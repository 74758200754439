var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.preparedGallery.length && !_vm.isLoading)?_c('div',{staticClass:"product__gallery--wrapper"},[_c('SfButton',{staticClass:"product__gallery--button sf-button--pure mobile-only",on:{"click":function($event){return _vm.$emit('handle-modal-gallery')}}},[_c('SfIcon',{attrs:{"icon":"zoomIn","color":"white"}})],1),_vm._v(" "),_c('carousel',{staticClass:"mobile-only",attrs:{"settings":_vm.carouselSettings,"carousel-items-qty":+_vm.preparedGallery.length,"container-class":"product-mobile-carousel"},on:{"carousel-initialized":function($event){_vm.isCarouselInit = true}}},_vm._l((_vm.preparedGallery),function(product,index){return _c('div',{key:index,staticClass:"swiper-slide",class:{ 'mobile-first-slide': index === 0 }},[(!product.video_content)?_c('img',{class:{
           'not_customGallery':!_vm.isCustomGallery,
           'is_customGallery':_vm.isCustomGallery,
         },attrs:{"width":'100%',"height":'100%',"src":product.mobile,"alt":product.alt,"fetchpriority":index === 0 ? 'high' : 'auto',"loading":index === 0 ?'eager' : 'lazy'}}):_vm._e(),_vm._v(" "),(_vm.isSensotex && index === 0)?_c('SensotexLogo'):_vm._e(),_vm._v(" "),(product.video_content)?_c('div',{staticClass:"product__video--wrapper product__gallery--item"},[_c('video',{ref:"videoMobile",refInFor:true,staticClass:"product__video",class:{
           'not_customGallery':!_vm.isCustomGallery,
           'is_customGallery':_vm.isCustomGallery,
          },attrs:{"width":_vm.computedImageWidth(1, null),"height":500,"poster":product.preview,"autoplay":"","muted":"","playsinline":"","loop":""},domProps:{"muted":true}},[_c('source',{attrs:{"type":"video/mp4; codecs=\"avc1.42E01E, mp4a.40.2\"","src":product.video_content.video_url}}),_vm._v(" "),_c('track',{attrs:{"src":"assets/sub.vtt","kind":"captions","srclang":"en"}})]),_vm._v(" "),_c('div',{staticClass:"product__video--btn-wrapper",on:{"click":function($event){return _vm.handleVideo('mobile')}}},[_c('SfIcon',{staticClass:"product__video--btn",attrs:{"color":"#fff","icon":_vm.videoPlayOrPause,"size":"2.5rem"}})],1)]):_vm._e()],1)}),0),_vm._v(" "),_c('div',{staticClass:"product__gallery desktop-only"},_vm._l((_vm.preparedGallery),function(product,index){return _c('div',{key:index,staticClass:"product__gallery--item"},[(!product.video_content)?_c('SfImage',{class:{
           'not_customGallery':!_vm.isCustomGallery,
           'is_customGallery':_vm.isCustomGallery,
           'first-img': index === 0,
         },attrs:{"width":_vm.computedImageWidth(index),"height":_vm.computedImageHeight(index),"src":product.desktop,"alt":product.alt,"fetchpriority":index === 0 ? 'high' : 'auto',"loading":index === 0 ? 'eager' : 'lazy'},on:{"click":function($event){_vm.handleModalGallery('image', _vm.getImageIndex(index))}}}):_vm._e(),_vm._v(" "),(_vm.isSensotex && index === 0)?_c('SensotexLogo'):_vm._e(),_vm._v(" "),(product.video_content)?_c('div',{staticClass:"product__video--wrapper product__gallery--item",class:{
             'not_customGallery':!_vm.isCustomGallery,
             'is_customGallery':_vm.isCustomGallery,
         }},[_c('video',{ref:"videoDesc",refInFor:true,staticClass:"product__video",attrs:{"width":_vm.computedImageWidth(1, null),"height":_vm.computedImageHeight(1, null),"poster":product.preview,"autoplay":"","muted":"","playsinline":"","loop":""},domProps:{"muted":true},on:{"click":function($event){return _vm.handleModalGallery( 'video' ,2 )}}},[_c('source',{attrs:{"type":"video/mp4; codecs=\"avc1.42E01E, mp4a.40.2\"","src":product.video_content.video_url}}),_vm._v(" "),_c('track',{attrs:{"src":"assets/sub.vtt","kind":"captions","srclang":"en"}})]),_vm._v(" "),_c('div',{staticClass:"product__video--btn-wrapper",on:{"click":function($event){return _vm.handleVideo('desktop')}}},[_c('SfIcon',{staticClass:"product__video--btn",attrs:{"color":"#fff","icon":_vm.videoPlayOrPause,"size":"2.5rem"}})],1)]):_vm._e()],1)}),0)],1):_c('div',{staticClass:"product__gallery--skeleton",class:{'is_customGallery':_vm.isCustomGallery}},[_c('SkeletonLoader',{attrs:{"width":"100%","height":"100%"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }