




































































































































































import {
  SfButton,
  SfImage,
  SfIcon,
  SkeletonLoader
} from '~/components';

import {
  defineComponent,
  computed,
  ref
} from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'ProductGallery',
  components: {
    SfButton,
    SfImage,
    SfIcon,
    SkeletonLoader,
    SensotexLogo: () => import('~/modules/catalog/product/components/fabric/SensotexLogo.vue'),
    Carousel: () => import('~/components/togas/Carousel.vue'),
  },
  props: {
    isLoading: {
      type: Boolean,
      required: true,
      default: false,
    },
    isCustomGallery: {
      type: Boolean,
      required: true,
      default: false,
    },
    isSensotex: {
      type: Boolean,
      required: true,
      default: false,
    },
    productGallery: {
      type: Array,
      required: true,
      default: () => [],
    },
    productVideoGallery: {
      type: Object,
      required: false,
      default: () => {},
    },
    imageSizes: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  emits: ['handle-modal-gallery'],
  setup(props, { emit }) {
    const isCarouselInit = ref(false);
    const carouselSettings = {
      breakpoints: {
        1024: {
          slidesPerView: 1,
          slidesPerGroup: 1,
        },
        768: {
          slidesPerView: 1,
          slidesPerGroup: 1,
        },
        480: {
          slidesPerView: 1,
          slidesPerGroup: 1,
        },
      },
    };
    const videoMobile = ref(null);
    const videoDesc = ref(null);
    const videoPlayOrPause = ref('pause');

    const numberOfSlides = computed(() => {
      if (props.productVideoGallery) {
        return props.productGallery.length + 1;
      } else {
        return props.productGallery.length;
      }
    });

    const preparedGallery = computed(() => {

      if (props.productVideoGallery.video_content) {
        return props.productGallery.slice(0, 2)
          .concat(props.productVideoGallery)
          .concat(props.productGallery.slice(2));
      } else {
        return props.productGallery;
      }
    });

    const computedImageWidth = (idx) => {
      return idx === 0
        ? props.imageSizes.productGallery.mainImageWidth
        : props.imageSizes.productGallery.imageWidth;
    };
    const computedImageHeight = (idx) => {
      if (idx === 0) {
        return props.imageSizes.productGallery.mainImageHeight;
      }
      if (props.isCustomGallery) {
        return props.imageSizes.productGallery.customImageHeight;
      }
      return props.imageSizes.productGallery.imageHeight;
    };

    const handleModalGallery = (type = 'image', index) => {
      if (type === 'image') {
        emit('handle-modal-gallery', type, index);
      }
    };

    const handleVideo = (type) => {
      const videoRef = type === 'mobile' ? videoMobile.value[0] : videoDesc.value[0];

      if (videoRef) {
        if (!videoRef.paused && videoPlayOrPause.value === 'pause') {
          videoRef.pause();
          videoPlayOrPause.value = 'play';
        } else if (videoRef.paused && videoPlayOrPause.value === 'play') {
          videoRef.play();
          videoPlayOrPause.value = 'pause';
        }
      }
    };

    const getImageIndex = (index) => {
      if (props.productVideoGallery.video_content) {
        return index < 2 ? index : index - 1;
      }
      return index;
    };

    return {
      isCarouselInit,
      carouselSettings,
      numberOfSlides,
      preparedGallery,
      computedImageWidth,
      computedImageHeight,
      handleModalGallery,
      handleVideo,
      videoDesc,
      videoMobile,
      videoPlayOrPause,
      getImageIndex,
    };
  },
});
