import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _444aaacb = () => interopDefault(import('../pages/AboutUs.vue' /* webpackChunkName: "" */))
const _9df88f12 = () => interopDefault(import('../modules/checkout/pages/Cart.vue' /* webpackChunkName: "" */))
const _7f94dfc6 = () => interopDefault(import('../modules/checkout/pages/Checkout.vue' /* webpackChunkName: "" */))
const _a014a72e = () => interopDefault(import('../modules/checkout/pages/Checkout/Billing.vue' /* webpackChunkName: "" */))
const _4ea4fe74 = () => interopDefault(import('../modules/checkout/pages/Checkout/Payment.vue' /* webpackChunkName: "" */))
const _e30bd1a0 = () => interopDefault(import('../modules/checkout/pages/Checkout/Shipping.vue' /* webpackChunkName: "" */))
const _7807e2d7 = () => interopDefault(import('../modules/checkout/pages/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _5dd78f50 = () => interopDefault(import('../modules/checkout/pages/Checkout/UserAccount.vue' /* webpackChunkName: "" */))
const _63125708 = () => interopDefault(import('../modules/landingPages/pages/Climafiber.vue' /* webpackChunkName: "" */))
const _29a01bee = () => interopDefault(import('../pages/Cms.vue' /* webpackChunkName: "pages/Cms" */))
const _388da71e = () => interopDefault(import('../pages/ContactUs.vue' /* webpackChunkName: "" */))
const _2cda5ef7 = () => interopDefault(import('../pages/ContactUsMideast.vue' /* webpackChunkName: "pages/ContactUsMideast" */))
const _1542237c = () => interopDefault(import('../pages/Csr.vue' /* webpackChunkName: "" */))
const _12d123b8 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _0b8f6992 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _112032e4 = () => interopDefault(import('../modules/customer/pages/MyAccount/LanguageSelector.vue' /* webpackChunkName: "" */))
const _35879d22 = () => interopDefault(import('../modules/customer/pages/MyAccount/LoginRegister.vue' /* webpackChunkName: "" */))
const _20eabadc = () => interopDefault(import('../modules/customer/pages/MyAccount/MyNewsletter.vue' /* webpackChunkName: "" */))
const _58f604e4 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _15113112 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyReviews.vue' /* webpackChunkName: "" */))
const _e76c2c44 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyWishlist.vue' /* webpackChunkName: "" */))
const _5264dc5c = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _59049bc1 = () => interopDefault(import('../modules/customer/pages/MyAccount/LocationSelector.vue' /* webpackChunkName: "" */))
const _b33a7844 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _7200f366 = () => interopDefault(import('../modules/customer/pages/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _35fac8bc = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _1fe62c3e = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _72d44a7d = () => interopDefault(import('../modules/servicePages/pages/CustomerCare.vue' /* webpackChunkName: "" */))
const _acb5a45a = () => interopDefault(import('../modules/servicePages/components/CustomerCareTabs.vue' /* webpackChunkName: "" */))
const _70b6557f = () => interopDefault(import('../pages/CustomerCare.vue' /* webpackChunkName: "pages/CustomerCare" */))
const _7361ca00 = () => interopDefault(import('../modules/landingPages/pages/Elysium.vue' /* webpackChunkName: "" */))
const _dced2288 = () => interopDefault(import('../pages/EmptyPage.vue' /* webpackChunkName: "pages/EmptyPage" */))
const _1dceadf0 = () => interopDefault(import('../pages/EmptyPageBanner.vue' /* webpackChunkName: "pages/EmptyPageBanner" */))
const _f979e012 = () => interopDefault(import('../pages/EmptyPageFooter.vue' /* webpackChunkName: "pages/EmptyPageFooter" */))
const _b715182e = () => interopDefault(import('../pages/EmptyPageHeader.vue' /* webpackChunkName: "pages/EmptyPageHeader" */))
const _36ac2b62 = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "" */))
const _12867656 = () => interopDefault(import('../modules/landingPages/pages/Esthira.vue' /* webpackChunkName: "" */))
const _754c4d56 = () => interopDefault(import('../pages/Faq.vue' /* webpackChunkName: "pages/Faq" */))
const _0acf4b18 = () => interopDefault(import('../modules/landingPages/pages/HandMade.vue' /* webpackChunkName: "" */))
const _4a7fa659 = () => interopDefault(import('../modules/landingPages/pages/Lezotex.vue' /* webpackChunkName: "" */))
const _34deffff = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "pages/Page" */))
const _54e7eea4 = () => interopDefault(import('../modules/landingPages/pages/QualityControl.vue' /* webpackChunkName: "" */))
const _41029898 = () => interopDefault(import('../pages/Search.vue' /* webpackChunkName: "" */))
const _0e8eb43f = () => interopDefault(import('../modules/landingPages/pages/Sensotex.vue' /* webpackChunkName: "" */))
const _73a8f375 = () => interopDefault(import('../pages/Sitemap.vue' /* webpackChunkName: "pages/Sitemap" */))
const _a853fba4 = () => interopDefault(import('../modules/quiz/pages/SleepSystem.vue' /* webpackChunkName: "" */))
const _59e4913b = () => interopDefault(import('../pages/StyleGuide.vue' /* webpackChunkName: "pages/StyleGuide" */))
const _f1508cc0 = () => interopDefault(import('../modules/wool/pages/Woolux.vue' /* webpackChunkName: "" */))
const _8c4bd5b2 = () => interopDefault(import('../modules/checkout/pages/Checkout/PaymentProcessing.vue' /* webpackChunkName: "" */))
const _63107144 = () => interopDefault(import('../modules/wool/pages/Camel.vue' /* webpackChunkName: "" */))
const _18db5e06 = () => interopDefault(import('../modules/wool/pages/Cashmere.vue' /* webpackChunkName: "" */))
const _8365e550 = () => interopDefault(import('../modules/wool/pages/Merino.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-us",
    component: _444aaacb,
    name: "about-us___en___default"
  }, {
    path: "/AboutUs",
    component: _444aaacb,
    name: "AboutUs___en___default"
  }, {
    path: "/cart",
    component: _9df88f12,
    name: "cart___en___default"
  }, {
    path: "/checkout",
    component: _7f94dfc6,
    name: "checkout___en___default",
    children: [{
      path: "billing",
      component: _a014a72e,
      name: "billing___en___default"
    }, {
      path: "payment",
      component: _4ea4fe74,
      name: "payment___en___default"
    }, {
      path: "shipping",
      component: _e30bd1a0,
      name: "shipping___en___default"
    }, {
      path: "thank-you",
      component: _7807e2d7,
      name: "thank-you___en___default"
    }, {
      path: "user-account",
      component: _5dd78f50,
      name: "user-account___en___default"
    }]
  }, {
    path: "/climafiber",
    component: _63125708,
    name: "climafiber___en___default"
  }, {
    path: "/Cms",
    component: _29a01bee,
    name: "Cms___en___default"
  }, {
    path: "/contact-us",
    component: _388da71e,
    name: "contact-us___en___default"
  }, {
    path: "/ContactUs",
    component: _388da71e,
    name: "ContactUs___en___default"
  }, {
    path: "/ContactUsMideast",
    component: _2cda5ef7,
    name: "ContactUsMideast___en___default"
  }, {
    path: "/csr",
    component: _1542237c,
    name: "csr___en___default"
  }, {
    path: "/Csr",
    component: _1542237c,
    name: "Csr___en___default"
  }, {
    path: "/customer",
    component: _12d123b8,
    meta: {"titleLabel":"My Account"},
    name: "customer___en___default",
    children: [{
      path: "addresses-details",
      component: _0b8f6992,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___en___default"
    }, {
      path: "language",
      component: _112032e4,
      meta: {"titleLabel":"Language"},
      name: "customer-language___en___default"
    }, {
      path: "login-register",
      component: _35879d22,
      meta: {"titleLabel":"Login / Register"},
      name: "customer-login-register___en___default"
    }, {
      path: "my-newsletter",
      component: _20eabadc,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___en___default"
    }, {
      path: "my-profile",
      component: _58f604e4,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___en___default"
    }, {
      path: "my-reviews",
      component: _15113112,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___en___default"
    }, {
      path: "my-wishlist",
      component: _e76c2c44,
      meta: {"titleLabel":"Wishlist"},
      name: "customer-my-wishlist___en___default"
    }, {
      path: "order-history",
      component: _5264dc5c,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___en___default"
    }, {
      path: "shipping-location",
      component: _59049bc1,
      meta: {"titleLabel":"Change Location"},
      name: "customer-shipping-location___en___default"
    }, {
      path: "addresses-details/create",
      component: _b33a7844,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___en___default"
    }, {
      path: "/en/reset-password",
      component: _7200f366,
      alias: "/customer/account/createPassword",
      name: "reset-password___en___default"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _35fac8bc,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___en___default"
    }, {
      path: "order-history/:orderId",
      component: _1fe62c3e,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___en___default"
    }]
  }, {
    path: "/customer-care",
    component: _72d44a7d,
    name: "customer-care___en___default",
    children: [{
      path: "gift-packing",
      component: _acb5a45a,
      name: "customer-care-gift-packing___en___default"
    }, {
      path: "impressum",
      component: _acb5a45a,
      name: "customer-care-impressum___en___default"
    }, {
      path: "loyalty-program",
      component: _acb5a45a,
      name: "customer-care-loyalty-program___en___default"
    }, {
      path: "privacy-policy",
      component: _acb5a45a,
      name: "customer-care-privacy-policy___en___default"
    }, {
      path: "shipment-returns",
      component: _acb5a45a,
      name: "customer-care-shipment-returns___en___default"
    }, {
      path: "terms-conditions",
      component: _acb5a45a,
      name: "customer-care-terms-conditions___en___default"
    }]
  }, {
    path: "/CustomerCare",
    component: _70b6557f,
    name: "CustomerCare___en___default"
  }, {
    path: "/elysium",
    component: _7361ca00,
    name: "elysium___en___default"
  }, {
    path: "/EmptyPage",
    component: _dced2288,
    name: "EmptyPage___en___default"
  }, {
    path: "/EmptyPageBanner",
    component: _1dceadf0,
    name: "EmptyPageBanner___en___default"
  }, {
    path: "/EmptyPageFooter",
    component: _f979e012,
    name: "EmptyPageFooter___en___default"
  }, {
    path: "/EmptyPageHeader",
    component: _b715182e,
    name: "EmptyPageHeader___en___default"
  }, {
    path: "/en",
    component: _36ac2b62,
    name: "home___en"
  }, {
    path: "/esthira",
    component: _12867656,
    name: "esthira___en___default"
  }, {
    path: "/Faq",
    component: _754c4d56,
    name: "Faq___en___default"
  }, {
    path: "/hand-made",
    component: _0acf4b18,
    name: "hand-made___en___default"
  }, {
    path: "/Home",
    component: _36ac2b62,
    name: "Home___en___default"
  }, {
    path: "/lezotex",
    component: _4a7fa659,
    name: "lezotex___en___default"
  }, {
    path: "/me",
    component: _36ac2b62,
    name: "home___me"
  }, {
    path: "/Page",
    component: _34deffff,
    name: "Page___en___default"
  }, {
    path: "/quality-control",
    component: _54e7eea4,
    name: "quality-control___en___default"
  }, {
    path: "/sa",
    component: _36ac2b62,
    name: "home___sa"
  }, {
    path: "/search",
    component: _41029898,
    name: "search___en___default"
  }, {
    path: "/Search",
    component: _41029898,
    name: "Search___en___default"
  }, {
    path: "/sensotex",
    component: _0e8eb43f,
    name: "sensotex___en___default"
  }, {
    path: "/Sitemap",
    component: _73a8f375,
    name: "Sitemap___en___default"
  }, {
    path: "/sleep-system",
    component: _a853fba4,
    name: "sleep-system___en___default"
  }, {
    path: "/StyleGuide",
    component: _59e4913b,
    name: "StyleGuide___en___default"
  }, {
    path: "/us",
    component: _36ac2b62,
    name: "home___us"
  }, {
    path: "/woolux",
    component: _f1508cc0,
    name: "woolux___en___default"
  }, {
    path: "/checkout/payment-processing",
    component: _8c4bd5b2,
    name: "payment-processing___en___default"
  }, {
    path: "/en/about-us",
    component: _444aaacb,
    name: "about-us___en"
  }, {
    path: "/en/AboutUs",
    component: _444aaacb,
    name: "AboutUs___en"
  }, {
    path: "/en/cart",
    component: _9df88f12,
    name: "cart___en"
  }, {
    path: "/en/checkout",
    component: _7f94dfc6,
    name: "checkout___en",
    children: [{
      path: "billing",
      component: _a014a72e,
      name: "billing___en"
    }, {
      path: "payment",
      component: _4ea4fe74,
      name: "payment___en"
    }, {
      path: "shipping",
      component: _e30bd1a0,
      name: "shipping___en"
    }, {
      path: "thank-you",
      component: _7807e2d7,
      name: "thank-you___en"
    }, {
      path: "user-account",
      component: _5dd78f50,
      name: "user-account___en"
    }]
  }, {
    path: "/en/climafiber",
    component: _63125708,
    name: "climafiber___en"
  }, {
    path: "/en/Cms",
    component: _29a01bee,
    name: "Cms___en"
  }, {
    path: "/en/contact-us",
    component: _388da71e,
    name: "contact-us___en"
  }, {
    path: "/en/ContactUs",
    component: _388da71e,
    name: "ContactUs___en"
  }, {
    path: "/en/ContactUsMideast",
    component: _2cda5ef7,
    name: "ContactUsMideast___en"
  }, {
    path: "/en/csr",
    component: _1542237c,
    name: "csr___en"
  }, {
    path: "/en/Csr",
    component: _1542237c,
    name: "Csr___en"
  }, {
    path: "/en/customer",
    component: _12d123b8,
    meta: {"titleLabel":"My Account"},
    name: "customer___en",
    children: [{
      path: "addresses-details",
      component: _0b8f6992,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___en"
    }, {
      path: "language",
      component: _112032e4,
      meta: {"titleLabel":"Language"},
      name: "customer-language___en"
    }, {
      path: "login-register",
      component: _35879d22,
      meta: {"titleLabel":"Login / Register"},
      name: "customer-login-register___en"
    }, {
      path: "my-newsletter",
      component: _20eabadc,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___en"
    }, {
      path: "my-profile",
      component: _58f604e4,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___en"
    }, {
      path: "my-reviews",
      component: _15113112,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___en"
    }, {
      path: "my-wishlist",
      component: _e76c2c44,
      meta: {"titleLabel":"Wishlist"},
      name: "customer-my-wishlist___en"
    }, {
      path: "order-history",
      component: _5264dc5c,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___en"
    }, {
      path: "shipping-location",
      component: _59049bc1,
      meta: {"titleLabel":"Change Location"},
      name: "customer-shipping-location___en"
    }, {
      path: "addresses-details/create",
      component: _b33a7844,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___en"
    }, {
      path: "/en/reset-password",
      component: _7200f366,
      alias: "/customer/account/createPassword",
      name: "reset-password___en"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _35fac8bc,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___en"
    }, {
      path: "order-history/:orderId",
      component: _1fe62c3e,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___en"
    }]
  }, {
    path: "/en/customer-care",
    component: _72d44a7d,
    name: "customer-care___en",
    children: [{
      path: "gift-packing",
      component: _acb5a45a,
      name: "customer-care-gift-packing___en"
    }, {
      path: "impressum",
      component: _acb5a45a,
      name: "customer-care-impressum___en"
    }, {
      path: "loyalty-program",
      component: _acb5a45a,
      name: "customer-care-loyalty-program___en"
    }, {
      path: "privacy-policy",
      component: _acb5a45a,
      name: "customer-care-privacy-policy___en"
    }, {
      path: "shipment-returns",
      component: _acb5a45a,
      name: "customer-care-shipment-returns___en"
    }, {
      path: "terms-conditions",
      component: _acb5a45a,
      name: "customer-care-terms-conditions___en"
    }]
  }, {
    path: "/en/CustomerCare",
    component: _70b6557f,
    name: "CustomerCare___en"
  }, {
    path: "/en/elysium",
    component: _7361ca00,
    name: "elysium___en"
  }, {
    path: "/en/EmptyPage",
    component: _dced2288,
    name: "EmptyPage___en"
  }, {
    path: "/en/EmptyPageBanner",
    component: _1dceadf0,
    name: "EmptyPageBanner___en"
  }, {
    path: "/en/EmptyPageFooter",
    component: _f979e012,
    name: "EmptyPageFooter___en"
  }, {
    path: "/en/EmptyPageHeader",
    component: _b715182e,
    name: "EmptyPageHeader___en"
  }, {
    path: "/en/esthira",
    component: _12867656,
    name: "esthira___en"
  }, {
    path: "/en/Faq",
    component: _754c4d56,
    name: "Faq___en"
  }, {
    path: "/en/hand-made",
    component: _0acf4b18,
    name: "hand-made___en"
  }, {
    path: "/en/Home",
    component: _36ac2b62,
    name: "Home___en"
  }, {
    path: "/en/lezotex",
    component: _4a7fa659,
    name: "lezotex___en"
  }, {
    path: "/en/Page",
    component: _34deffff,
    name: "Page___en"
  }, {
    path: "/en/quality-control",
    component: _54e7eea4,
    name: "quality-control___en"
  }, {
    path: "/en/search",
    component: _41029898,
    name: "search___en"
  }, {
    path: "/en/Search",
    component: _41029898,
    name: "Search___en"
  }, {
    path: "/en/sensotex",
    component: _0e8eb43f,
    name: "sensotex___en"
  }, {
    path: "/en/Sitemap",
    component: _73a8f375,
    name: "Sitemap___en"
  }, {
    path: "/en/sleep-system",
    component: _a853fba4,
    name: "sleep-system___en"
  }, {
    path: "/en/StyleGuide",
    component: _59e4913b,
    name: "StyleGuide___en"
  }, {
    path: "/en/woolux",
    component: _f1508cc0,
    name: "woolux___en"
  }, {
    path: "/me/about-us",
    component: _444aaacb,
    name: "about-us___me"
  }, {
    path: "/me/AboutUs",
    component: _444aaacb,
    name: "AboutUs___me"
  }, {
    path: "/me/cart",
    component: _9df88f12,
    name: "cart___me"
  }, {
    path: "/me/checkout",
    component: _7f94dfc6,
    name: "checkout___me",
    children: [{
      path: "billing",
      component: _a014a72e,
      name: "billing___me"
    }, {
      path: "payment",
      component: _4ea4fe74,
      name: "payment___me"
    }, {
      path: "shipping",
      component: _e30bd1a0,
      name: "shipping___me"
    }, {
      path: "thank-you",
      component: _7807e2d7,
      name: "thank-you___me"
    }, {
      path: "user-account",
      component: _5dd78f50,
      name: "user-account___me"
    }]
  }, {
    path: "/me/climafiber",
    component: _63125708,
    name: "climafiber___me"
  }, {
    path: "/me/Cms",
    component: _29a01bee,
    name: "Cms___me"
  }, {
    path: "/me/contact-us",
    component: _388da71e,
    name: "contact-us___me"
  }, {
    path: "/me/ContactUs",
    component: _388da71e,
    name: "ContactUs___me"
  }, {
    path: "/me/ContactUsMideast",
    component: _2cda5ef7,
    name: "ContactUsMideast___me"
  }, {
    path: "/me/csr",
    component: _1542237c,
    name: "csr___me"
  }, {
    path: "/me/Csr",
    component: _1542237c,
    name: "Csr___me"
  }, {
    path: "/me/customer",
    component: _12d123b8,
    meta: {"titleLabel":"My Account"},
    name: "customer___me",
    children: [{
      path: "addresses-details",
      component: _0b8f6992,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___me"
    }, {
      path: "language",
      component: _112032e4,
      meta: {"titleLabel":"Language"},
      name: "customer-language___me"
    }, {
      path: "login-register",
      component: _35879d22,
      meta: {"titleLabel":"Login / Register"},
      name: "customer-login-register___me"
    }, {
      path: "my-newsletter",
      component: _20eabadc,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___me"
    }, {
      path: "my-profile",
      component: _58f604e4,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___me"
    }, {
      path: "my-reviews",
      component: _15113112,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___me"
    }, {
      path: "my-wishlist",
      component: _e76c2c44,
      meta: {"titleLabel":"Wishlist"},
      name: "customer-my-wishlist___me"
    }, {
      path: "order-history",
      component: _5264dc5c,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___me"
    }, {
      path: "shipping-location",
      component: _59049bc1,
      meta: {"titleLabel":"Change Location"},
      name: "customer-shipping-location___me"
    }, {
      path: "addresses-details/create",
      component: _b33a7844,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___me"
    }, {
      path: "/me/reset-password",
      component: _7200f366,
      alias: "/customer/account/createPassword",
      name: "reset-password___me"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _35fac8bc,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___me"
    }, {
      path: "order-history/:orderId",
      component: _1fe62c3e,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___me"
    }]
  }, {
    path: "/me/customer-care",
    component: _72d44a7d,
    name: "customer-care___me",
    children: [{
      path: "gift-packing",
      component: _acb5a45a,
      name: "customer-care-gift-packing___me"
    }, {
      path: "impressum",
      component: _acb5a45a,
      name: "customer-care-impressum___me"
    }, {
      path: "loyalty-program",
      component: _acb5a45a,
      name: "customer-care-loyalty-program___me"
    }, {
      path: "privacy-policy",
      component: _acb5a45a,
      name: "customer-care-privacy-policy___me"
    }, {
      path: "shipment-returns",
      component: _acb5a45a,
      name: "customer-care-shipment-returns___me"
    }, {
      path: "terms-conditions",
      component: _acb5a45a,
      name: "customer-care-terms-conditions___me"
    }]
  }, {
    path: "/me/CustomerCare",
    component: _70b6557f,
    name: "CustomerCare___me"
  }, {
    path: "/me/elysium",
    component: _7361ca00,
    name: "elysium___me"
  }, {
    path: "/me/EmptyPage",
    component: _dced2288,
    name: "EmptyPage___me"
  }, {
    path: "/me/EmptyPageBanner",
    component: _1dceadf0,
    name: "EmptyPageBanner___me"
  }, {
    path: "/me/EmptyPageFooter",
    component: _f979e012,
    name: "EmptyPageFooter___me"
  }, {
    path: "/me/EmptyPageHeader",
    component: _b715182e,
    name: "EmptyPageHeader___me"
  }, {
    path: "/me/esthira",
    component: _12867656,
    name: "esthira___me"
  }, {
    path: "/me/Faq",
    component: _754c4d56,
    name: "Faq___me"
  }, {
    path: "/me/hand-made",
    component: _0acf4b18,
    name: "hand-made___me"
  }, {
    path: "/me/Home",
    component: _36ac2b62,
    name: "Home___me"
  }, {
    path: "/me/lezotex",
    component: _4a7fa659,
    name: "lezotex___me"
  }, {
    path: "/me/Page",
    component: _34deffff,
    name: "Page___me"
  }, {
    path: "/me/quality-control",
    component: _54e7eea4,
    name: "quality-control___me"
  }, {
    path: "/me/search",
    component: _41029898,
    name: "search___me"
  }, {
    path: "/me/Search",
    component: _41029898,
    name: "Search___me"
  }, {
    path: "/me/sensotex",
    component: _0e8eb43f,
    name: "sensotex___me"
  }, {
    path: "/me/Sitemap",
    component: _73a8f375,
    name: "Sitemap___me"
  }, {
    path: "/me/sleep-system",
    component: _a853fba4,
    name: "sleep-system___me"
  }, {
    path: "/me/StyleGuide",
    component: _59e4913b,
    name: "StyleGuide___me"
  }, {
    path: "/me/woolux",
    component: _f1508cc0,
    name: "woolux___me"
  }, {
    path: "/sa/about-us",
    component: _444aaacb,
    name: "about-us___sa"
  }, {
    path: "/sa/AboutUs",
    component: _444aaacb,
    name: "AboutUs___sa"
  }, {
    path: "/sa/cart",
    component: _9df88f12,
    name: "cart___sa"
  }, {
    path: "/sa/checkout",
    component: _7f94dfc6,
    name: "checkout___sa",
    children: [{
      path: "billing",
      component: _a014a72e,
      name: "billing___sa"
    }, {
      path: "payment",
      component: _4ea4fe74,
      name: "payment___sa"
    }, {
      path: "shipping",
      component: _e30bd1a0,
      name: "shipping___sa"
    }, {
      path: "thank-you",
      component: _7807e2d7,
      name: "thank-you___sa"
    }, {
      path: "user-account",
      component: _5dd78f50,
      name: "user-account___sa"
    }]
  }, {
    path: "/sa/climafiber",
    component: _63125708,
    name: "climafiber___sa"
  }, {
    path: "/sa/Cms",
    component: _29a01bee,
    name: "Cms___sa"
  }, {
    path: "/sa/contact-us",
    component: _388da71e,
    name: "contact-us___sa"
  }, {
    path: "/sa/ContactUs",
    component: _388da71e,
    name: "ContactUs___sa"
  }, {
    path: "/sa/ContactUsMideast",
    component: _2cda5ef7,
    name: "ContactUsMideast___sa"
  }, {
    path: "/sa/csr",
    component: _1542237c,
    name: "csr___sa"
  }, {
    path: "/sa/Csr",
    component: _1542237c,
    name: "Csr___sa"
  }, {
    path: "/sa/customer",
    component: _12d123b8,
    meta: {"titleLabel":"My Account"},
    name: "customer___sa",
    children: [{
      path: "addresses-details",
      component: _0b8f6992,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___sa"
    }, {
      path: "language",
      component: _112032e4,
      meta: {"titleLabel":"Language"},
      name: "customer-language___sa"
    }, {
      path: "login-register",
      component: _35879d22,
      meta: {"titleLabel":"Login / Register"},
      name: "customer-login-register___sa"
    }, {
      path: "my-newsletter",
      component: _20eabadc,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___sa"
    }, {
      path: "my-profile",
      component: _58f604e4,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___sa"
    }, {
      path: "my-reviews",
      component: _15113112,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___sa"
    }, {
      path: "my-wishlist",
      component: _e76c2c44,
      meta: {"titleLabel":"Wishlist"},
      name: "customer-my-wishlist___sa"
    }, {
      path: "order-history",
      component: _5264dc5c,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___sa"
    }, {
      path: "shipping-location",
      component: _59049bc1,
      meta: {"titleLabel":"Change Location"},
      name: "customer-shipping-location___sa"
    }, {
      path: "addresses-details/create",
      component: _b33a7844,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___sa"
    }, {
      path: "/sa/reset-password",
      component: _7200f366,
      alias: "/customer/account/createPassword",
      name: "reset-password___sa"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _35fac8bc,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___sa"
    }, {
      path: "order-history/:orderId",
      component: _1fe62c3e,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___sa"
    }]
  }, {
    path: "/sa/customer-care",
    component: _72d44a7d,
    name: "customer-care___sa",
    children: [{
      path: "gift-packing",
      component: _acb5a45a,
      name: "customer-care-gift-packing___sa"
    }, {
      path: "impressum",
      component: _acb5a45a,
      name: "customer-care-impressum___sa"
    }, {
      path: "loyalty-program",
      component: _acb5a45a,
      name: "customer-care-loyalty-program___sa"
    }, {
      path: "privacy-policy",
      component: _acb5a45a,
      name: "customer-care-privacy-policy___sa"
    }, {
      path: "shipment-returns",
      component: _acb5a45a,
      name: "customer-care-shipment-returns___sa"
    }, {
      path: "terms-conditions",
      component: _acb5a45a,
      name: "customer-care-terms-conditions___sa"
    }]
  }, {
    path: "/sa/CustomerCare",
    component: _70b6557f,
    name: "CustomerCare___sa"
  }, {
    path: "/sa/elysium",
    component: _7361ca00,
    name: "elysium___sa"
  }, {
    path: "/sa/EmptyPage",
    component: _dced2288,
    name: "EmptyPage___sa"
  }, {
    path: "/sa/EmptyPageBanner",
    component: _1dceadf0,
    name: "EmptyPageBanner___sa"
  }, {
    path: "/sa/EmptyPageFooter",
    component: _f979e012,
    name: "EmptyPageFooter___sa"
  }, {
    path: "/sa/EmptyPageHeader",
    component: _b715182e,
    name: "EmptyPageHeader___sa"
  }, {
    path: "/sa/esthira",
    component: _12867656,
    name: "esthira___sa"
  }, {
    path: "/sa/Faq",
    component: _754c4d56,
    name: "Faq___sa"
  }, {
    path: "/sa/hand-made",
    component: _0acf4b18,
    name: "hand-made___sa"
  }, {
    path: "/sa/Home",
    component: _36ac2b62,
    name: "Home___sa"
  }, {
    path: "/sa/lezotex",
    component: _4a7fa659,
    name: "lezotex___sa"
  }, {
    path: "/sa/Page",
    component: _34deffff,
    name: "Page___sa"
  }, {
    path: "/sa/quality-control",
    component: _54e7eea4,
    name: "quality-control___sa"
  }, {
    path: "/sa/search",
    component: _41029898,
    name: "search___sa"
  }, {
    path: "/sa/Search",
    component: _41029898,
    name: "Search___sa"
  }, {
    path: "/sa/sensotex",
    component: _0e8eb43f,
    name: "sensotex___sa"
  }, {
    path: "/sa/Sitemap",
    component: _73a8f375,
    name: "Sitemap___sa"
  }, {
    path: "/sa/sleep-system",
    component: _a853fba4,
    name: "sleep-system___sa"
  }, {
    path: "/sa/StyleGuide",
    component: _59e4913b,
    name: "StyleGuide___sa"
  }, {
    path: "/sa/woolux",
    component: _f1508cc0,
    name: "woolux___sa"
  }, {
    path: "/us/about-us",
    component: _444aaacb,
    name: "about-us___us"
  }, {
    path: "/us/AboutUs",
    component: _444aaacb,
    name: "AboutUs___us"
  }, {
    path: "/us/cart",
    component: _9df88f12,
    name: "cart___us"
  }, {
    path: "/us/checkout",
    component: _7f94dfc6,
    name: "checkout___us",
    children: [{
      path: "billing",
      component: _a014a72e,
      name: "billing___us"
    }, {
      path: "payment",
      component: _4ea4fe74,
      name: "payment___us"
    }, {
      path: "shipping",
      component: _e30bd1a0,
      name: "shipping___us"
    }, {
      path: "thank-you",
      component: _7807e2d7,
      name: "thank-you___us"
    }, {
      path: "user-account",
      component: _5dd78f50,
      name: "user-account___us"
    }]
  }, {
    path: "/us/climafiber",
    component: _63125708,
    name: "climafiber___us"
  }, {
    path: "/us/Cms",
    component: _29a01bee,
    name: "Cms___us"
  }, {
    path: "/us/contact-us",
    component: _388da71e,
    name: "contact-us___us"
  }, {
    path: "/us/ContactUs",
    component: _388da71e,
    name: "ContactUs___us"
  }, {
    path: "/us/ContactUsMideast",
    component: _2cda5ef7,
    name: "ContactUsMideast___us"
  }, {
    path: "/us/csr",
    component: _1542237c,
    name: "csr___us"
  }, {
    path: "/us/Csr",
    component: _1542237c,
    name: "Csr___us"
  }, {
    path: "/us/customer",
    component: _12d123b8,
    meta: {"titleLabel":"My Account"},
    name: "customer___us",
    children: [{
      path: "addresses-details",
      component: _0b8f6992,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___us"
    }, {
      path: "language",
      component: _112032e4,
      meta: {"titleLabel":"Language"},
      name: "customer-language___us"
    }, {
      path: "login-register",
      component: _35879d22,
      meta: {"titleLabel":"Login / Register"},
      name: "customer-login-register___us"
    }, {
      path: "my-newsletter",
      component: _20eabadc,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___us"
    }, {
      path: "my-profile",
      component: _58f604e4,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___us"
    }, {
      path: "my-reviews",
      component: _15113112,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___us"
    }, {
      path: "my-wishlist",
      component: _e76c2c44,
      meta: {"titleLabel":"Wishlist"},
      name: "customer-my-wishlist___us"
    }, {
      path: "order-history",
      component: _5264dc5c,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___us"
    }, {
      path: "shipping-location",
      component: _59049bc1,
      meta: {"titleLabel":"Change Location"},
      name: "customer-shipping-location___us"
    }, {
      path: "addresses-details/create",
      component: _b33a7844,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___us"
    }, {
      path: "/us/reset-password",
      component: _7200f366,
      alias: "/customer/account/createPassword",
      name: "reset-password___us"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _35fac8bc,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___us"
    }, {
      path: "order-history/:orderId",
      component: _1fe62c3e,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___us"
    }]
  }, {
    path: "/us/customer-care",
    component: _72d44a7d,
    name: "customer-care___us",
    children: [{
      path: "gift-packing",
      component: _acb5a45a,
      name: "customer-care-gift-packing___us"
    }, {
      path: "impressum",
      component: _acb5a45a,
      name: "customer-care-impressum___us"
    }, {
      path: "loyalty-program",
      component: _acb5a45a,
      name: "customer-care-loyalty-program___us"
    }, {
      path: "privacy-policy",
      component: _acb5a45a,
      name: "customer-care-privacy-policy___us"
    }, {
      path: "shipment-returns",
      component: _acb5a45a,
      name: "customer-care-shipment-returns___us"
    }, {
      path: "terms-conditions",
      component: _acb5a45a,
      name: "customer-care-terms-conditions___us"
    }]
  }, {
    path: "/us/CustomerCare",
    component: _70b6557f,
    name: "CustomerCare___us"
  }, {
    path: "/us/elysium",
    component: _7361ca00,
    name: "elysium___us"
  }, {
    path: "/us/EmptyPage",
    component: _dced2288,
    name: "EmptyPage___us"
  }, {
    path: "/us/EmptyPageBanner",
    component: _1dceadf0,
    name: "EmptyPageBanner___us"
  }, {
    path: "/us/EmptyPageFooter",
    component: _f979e012,
    name: "EmptyPageFooter___us"
  }, {
    path: "/us/EmptyPageHeader",
    component: _b715182e,
    name: "EmptyPageHeader___us"
  }, {
    path: "/us/esthira",
    component: _12867656,
    name: "esthira___us"
  }, {
    path: "/us/Faq",
    component: _754c4d56,
    name: "Faq___us"
  }, {
    path: "/us/hand-made",
    component: _0acf4b18,
    name: "hand-made___us"
  }, {
    path: "/us/Home",
    component: _36ac2b62,
    name: "Home___us"
  }, {
    path: "/us/lezotex",
    component: _4a7fa659,
    name: "lezotex___us"
  }, {
    path: "/us/Page",
    component: _34deffff,
    name: "Page___us"
  }, {
    path: "/us/quality-control",
    component: _54e7eea4,
    name: "quality-control___us"
  }, {
    path: "/us/search",
    component: _41029898,
    name: "search___us"
  }, {
    path: "/us/Search",
    component: _41029898,
    name: "Search___us"
  }, {
    path: "/us/sensotex",
    component: _0e8eb43f,
    name: "sensotex___us"
  }, {
    path: "/us/Sitemap",
    component: _73a8f375,
    name: "Sitemap___us"
  }, {
    path: "/us/sleep-system",
    component: _a853fba4,
    name: "sleep-system___us"
  }, {
    path: "/us/StyleGuide",
    component: _59e4913b,
    name: "StyleGuide___us"
  }, {
    path: "/us/woolux",
    component: _f1508cc0,
    name: "woolux___us"
  }, {
    path: "/woolux/camel",
    component: _63107144,
    name: "camel___en___default"
  }, {
    path: "/woolux/cashmere",
    component: _18db5e06,
    name: "cashmere___en___default"
  }, {
    path: "/woolux/merino",
    component: _8365e550,
    name: "merino___en___default"
  }, {
    path: "/en/checkout/payment-processing",
    component: _8c4bd5b2,
    name: "payment-processing___en"
  }, {
    path: "/en/woolux/camel",
    component: _63107144,
    name: "camel___en"
  }, {
    path: "/en/woolux/cashmere",
    component: _18db5e06,
    name: "cashmere___en"
  }, {
    path: "/en/woolux/merino",
    component: _8365e550,
    name: "merino___en"
  }, {
    path: "/me/checkout/payment-processing",
    component: _8c4bd5b2,
    name: "payment-processing___me"
  }, {
    path: "/me/woolux/camel",
    component: _63107144,
    name: "camel___me"
  }, {
    path: "/me/woolux/cashmere",
    component: _18db5e06,
    name: "cashmere___me"
  }, {
    path: "/me/woolux/merino",
    component: _8365e550,
    name: "merino___me"
  }, {
    path: "/sa/checkout/payment-processing",
    component: _8c4bd5b2,
    name: "payment-processing___sa"
  }, {
    path: "/sa/woolux/camel",
    component: _63107144,
    name: "camel___sa"
  }, {
    path: "/sa/woolux/cashmere",
    component: _18db5e06,
    name: "cashmere___sa"
  }, {
    path: "/sa/woolux/merino",
    component: _8365e550,
    name: "merino___sa"
  }, {
    path: "/us/checkout/payment-processing",
    component: _8c4bd5b2,
    name: "payment-processing___us"
  }, {
    path: "/us/woolux/camel",
    component: _63107144,
    name: "camel___us"
  }, {
    path: "/us/woolux/cashmere",
    component: _18db5e06,
    name: "cashmere___us"
  }, {
    path: "/us/woolux/merino",
    component: _8365e550,
    name: "merino___us"
  }, {
    path: "/en/:slug+",
    component: _34deffff,
    name: "page___en"
  }, {
    path: "/me/:slug+",
    component: _34deffff,
    name: "page___me"
  }, {
    path: "/sa/:slug+",
    component: _34deffff,
    name: "page___sa"
  }, {
    path: "/us/:slug+",
    component: _34deffff,
    name: "page___us"
  }, {
    path: "/",
    component: _36ac2b62,
    name: "home___en___default"
  }, {
    path: "/:slug+",
    component: _34deffff,
    name: "page___en___default"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
